<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.milenioFund')"
          :message="errors.milenio_funds"
          :type="types.milenio_funds"
        >
          <b-input aria-label="milenio-fund" v-model="milenio_funds" type="text"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.otherFund')"
          :message="errors.other_funds"
          :type="types.other_funds"
        >
          <b-input aria-label="other-fund" v-model="other_funds" type="text"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.projectLink')"
          :message="errors.link"
          :type="types.link"
        >
          <b-input aria-label="project-link" v-model="link" type="text"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.activityType')"
          :message="errors.activityType"
          :type="types.activityType"
        >
          <b-autocomplete
            v-model="activityType"
            :placeholder="$t('divisionCreateExtraForm.activityTypePlaceHolder')"
            open-on-focus
            :data="activityTypeOptions"
            field="name"
            clearable
            @select="
              (option) =>
                $store.commit('project/updateExtraInfoValue', {
                  field: 'activity_type_id',
                  value: option ? option.id : null
                })
            "
          />
        </b-field>
      </div>
    </div>

    <h3 class="subtitle is-5 has-roboto-slab">
      {{ $t('divisionCreateExtraForm.companyPartnerTitle') }}
    </h3>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.companyPartnerName')"
          :message="errors.companyParnetName"
          :type="types.companyParnetName"
        >
          <b-input
            aria-label="companyPartnerName"
            v-model="companyParnetName"
            type="text"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.companyPartnerCountry')"
          :message="errors.companyPartnerCountry"
          :type="types.companyPartnerCountry"
        >
          <country-selector v-model="companyPartnerCountry" />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.companyPartnerCity')"
          :message="errors.companyPartnerCity"
          :type="types.companyPartnerCity"
        >
          <b-input aria-label="project-link" v-model="companyPartnerCity" type="text"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('divisionCreateExtraForm.companyPartnerActivityType')"
          :message="errors.companyPartnerActivityType"
          :type="types.companyPartnerActivityType"
        >
          <b-autocomplete
            v-model="partnerCompanyActivityType"
            :placeholder="$t('divisionCreateExtraForm.companyPartnerActivityTypePlaceHolder')"
            open-on-focus
            :data="PartnerCompanyActivityTypeOptions"
            field="name"
            clearable
            @select="
              (option) =>
                $store.commit('project/updateExtraInfoValue', {
                  field: 'companyPartnerActivityType',
                  subfield: 'activity_type_id',
                  value: option ? option.id : null
                })
            "
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getProjectActivitiesType, getPartnerCompanyActivityType } from '@/requests/divisions'
import CountrySelector from '@/components/CountrySelector.vue'
const { mapState } = createNamespacedHelpers('project')

export default {
  components: {
    CountrySelector
  },
  name: 'ProjectCreateExtraInfoForm',
  props: {
    project: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activityType: null,
      partnerCompanyActivityType: null,
      activityTypeOptions: [],
      PartnerCompanyActivityTypeOptions: []
    }
  },
  computed: {
    ...mapState(['extraInformation']),
    ...mapState(['errors']),
    ...mapState(['types']),
    milenio_funds: {
      get() {
        return this.extraInformation.milenio_funds
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', { field: 'milenio_funds', value })
      }
    },
    other_funds: {
      get() {
        return this.extraInformation.other_funds
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', { field: 'other_funds', value })
      }
    },
    link: {
      get() {
        return this.extraInformation.link
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', { field: 'link', value })
      }
    },
    companyParnetName: {
      get() {
        return this.extraInformation.partner_company.name
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', {
          field: 'partnerCompanyName',
          subfield: 'name',
          value
        })
      }
    },
    companyPartnerCountry: {
      get() {
        return this.extraInformation.partner_company.country
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', {
          field: 'partnerCompanyCity',
          subfield: 'country',
          value
        })
      }
    },
    companyPartnerCity: {
      get() {
        return this.extraInformation.partner_company.city
      },
      set(value) {
        this.$store.commit('project/updateExtraInfoValue', {
          field: 'partnerCompanyCity',
          subfield: 'city',
          value
        })
      }
    }
  },
  async mounted() {
    this.activityTypeOptions = await getProjectActivitiesType()
    this.PartnerCompanyActivityTypeOptions = await getPartnerCompanyActivityType()
    this.activityType = this.extraInformation.activity_type_id
      ? this.activityTypeOptions.find(
          (option) => option.id === this.extraInformation.activity_type_id
        ).name
      : null
    this.partnerCompanyActivityType = this.extraInformation.partner_company.activity_type_id
      ? this.activityTypeOptions.find(
          (option) => option.id === this.extraInformation.partner_company.activity_type_id
        ).name
      : null
  }
}
</script>
